import axios from "axios";
import cookieStorage from "./cookie-storage";
// import { useHistory } from "react-router-dom";
class HttpService {
  static get(url, config) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios
          .get(`${url}`, config)
          .catch(async (error) => {
            if (
              (error && error.response && error.response.status === 401) ||
              error.response.data["message"] === "JWT not availaible"
            ) {
              this.clearData();
            } else if (
              error.response.data["message"] ===
                "App is temporarily unavailable" ||
              error.response.data["code"] === "SERVER_DOWN"
            ) {
              console.log("Server down"); //
              this.serverDownScreen();
            }

            throw error["message"] ? error : error.response.data;
          })
          .then((response) => {
            // if (response.headers["content-type"] === "video/mp4")
            //   resolve(
            //     new Blob([response.data], {
            //       type: response.headers["content-type"],
            //     })
            //   );
            // else
            resolve(response.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
  static post(url, body, config) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios
          .post(`${url}`, body, config)
          .catch(async (error) => {
            if (
              (error && error.response && error.response.status === 401) ||
              error.response.data["message"] === "JWT not availaible"
            ) {
              this.clearData();
            } else if (
              error.response.data["message"] ===
                "App is temporarily unavailable" ||
              error.response.data["code"] === "SERVER_DOWN"
            ) {
              this.serverDownScreen();
            }

            throw error["message"] ? error : error.response.data;
          })
          .then((response) => {
            resolve(response.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
  static put(url, body, config) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios
          .put(`${url}`, body, config)
          .catch(async (error) => {
            if (
              (error && error.response && error.response.status === 401) ||
              error.response.data["message"] === "JWT not availaible"
            ) {
              this.clearData();
            } else if (
              error.response.data["message"] ===
                "App is temporarily unavailable" ||
              error.response.data["code"] === "SERVER_DOWN"
            ) {
              this.serverDownScreen();
            }

            throw error["message"] ? error : error.response.data;
          })
          .then((response) => {
            resolve(response.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  static clearData() {
    localStorage.clear();
    cookieStorage.deleteCookie("Authorization");
    setTimeout(() => {
      window.location = "/";
    }, 1000);
  }

  static serverDownScreen() {
    //console.log("entering Server down");
    window.location.replace("/maintenance");
  }
}

export default HttpService;
